<template>
  <div>
    <b-card class="mb-1">
      <b-card-title class="mb-1">
        Reporte de hostigamiento según intervalo de fechas
        <span>
          <b-button
            variant="info"
            size="sm"
            class="float-right"
            @click="irAformulario"
          >
            ir al formulario
          </b-button>
        </span>
      </b-card-title>
      <b-card-sub-title class="mb-2">
        Seleccione el intervalo de fechas para calcular el reporte
      </b-card-sub-title>
      <b-row>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <label for="example-datepicker">Fecha Inicio</label>
          <b-form-datepicker
            v-model="fechaInicio"
            class="mb-1"
            @input="refreshTabla"
          />
        </b-col>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <label for="example-datepicker">Fecha Fin</label>
          <b-form-datepicker
            v-model="fechaFin"
            class="mb-1"
            @input="refreshTabla"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          xl="3"
          lg="3"
          md="3"
          sm="12"
        >
          <!-- apex chart -->

          <div class="text-center">
            <strong class="text-primary">TOTAL DENUNCIAS RECIBIDAS</strong>
            <h2 class="text-primary">
              {{ denuncias_recibidas }}
            </h2>
          </div>
          <vue-apex-charts
            class=" m-0 p-0"
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptionsTotal"
            :series="seriesTotal"
          />
        </b-col>
        <b-col
          xl="3"
          lg="3"
          md="3"
          sm="12"
        >
          <!-- apex chart -->
          <div class="text-center">
            <strong class="text-warning">DENUNCIAS PENDIENTES</strong>
            <h2 class="text-warning">
              {{ denuncias_pendientes }}
            </h2>
          </div>
          <vue-apex-charts
            class=" m-0 p-0"
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptionsPendientes"
            :series="seriesPendientes"
          />
        </b-col>
        <b-col
          xl="3"
          lg="3"
          md="3"
          sm="12"
        >
          <!-- apex chart -->
          <div class="text-center">
            <strong class="text-danger">DENUNCIAS FALSAS</strong>
            <h2 class="text-danger">
              {{ denuncias_falsas }}
            </h2>
          </div>
          <vue-apex-charts
            class=" m-0 p-0"
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptionsFalsas"
            :series="seriesFalsas"
          />
        </b-col>
        <b-col
          xl="3"
          lg="3"
          md="3"
          sm="12"
        >

          <!-- apex chart -->
          <div class="text-center">
            <strong class="text-success">DENUNCIAS CONFIRMADAS</strong>
            <h2 class="text-success">
              {{ denuncias_confirmadas }}
            </h2>
          </div>
          <vue-apex-charts
            class=" m-0 p-0"
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptionsConfirmado"
            :series="seriesConfirmadas"
          />

        </b-col>
      </b-row>

    </b-card>

    <IndicadorTasaCrecimiento />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormDatepicker, BCardTitle, BCardSubTitle, BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'

import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import reporteStoreModule from './reporteStoreModule'
import IndicadorTasaCrecimiento from './IndicadorTasaCrecimientoDenuncias.vue'

export default {
  components: {
    BCard,
    BFormDatepicker,
    BCardTitle,
    BCardSubTitle,
    VueApexCharts,
    BRow,
    BCol,
    BButton,
    // extern
    IndicadorTasaCrecimiento,

  },
  data() {
    return {
      idFormulario: 0,

      denuncias_recibidas: 0,
      denuncias_pendientes: 0,
      denuncias_falsas: 0,
      denuncias_confirmadas: 0,

      seriesTotal: [100],
      seriesPendientes: [0],
      seriesFalsas: [0],
      seriesConfirmadas: [0],
      data: {},
      fechaInicio: `${this.anioHoy()}-01-01`,
      fechaFin: `${this.anioHoy()}-12-31`,
      supportTrackerRadialBar: {
        chartOptionsTotal: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.primary],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Total denuncias'],
        },
        chartOptionsConfirmado: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.success],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Denuncias confirmadas'],
        },
        chartOptionsFalsas: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Denuncias falsas'],
        },
        chartOptionsPendientes: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.warning],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Denuncias Pendientes'],
        },
      },
    }
  },
  created() {
    this.$http.get('/formulario/HOSTIGAMIENTO-DENUNCIA/buscar')
      .then(res => {
        this.idFormulario = res.data.id
      })
  },
  mounted() {
    this.refreshTabla()
  },
  methods: {
    refreshTabla() {
      const DATA = {
        fecha_inicio: this.fechaInicio,
        fecha_fin: this.fechaFin,
      }

      store.dispatch('reportes-hostigamiento-store-module/getReporteHostigamientoRango', DATA).then(response => {
        this.denuncias_recibidas = response.data.denuncias_recibidas
        this.denuncias_pendientes = response.data.denuncias_pendientes
        this.denuncias_falsas = response.data.denuncias_falsas
        this.denuncias_confirmadas = response.data.denuncias_confirmadas

        if (this.denuncias_recibidas !== 0) {
          this.seriesPendientes.splice(0, 1, Math.round((this.denuncias_pendientes * 100) / this.denuncias_recibidas))
          this.seriesFalsas.splice(0, 1, Math.round((this.denuncias_falsas * 100) / this.denuncias_recibidas))
          this.seriesConfirmadas.splice(0, 1, Math.round((this.denuncias_confirmadas * 100) / this.denuncias_recibidas))
        }
      })
    },
    irAformulario() {
      const routeData = this.$router.resolve({ name: 'vista-anonima-x', params: { id_formulariox: this.idFormulario } })
      window.open(routeData.href, '_blank')
    },

    anioHoy() {
      const today = new Date()
      const yyyy = today.getFullYear()

      return `${yyyy}`
    },
  },
  setup() {
    const REPORTE_HOSTIGAMIENTO_RANGO_APP_STORE_MODULE_NAME = 'reportes-hostigamiento-store-module'

    // Register module
    if (!store.hasModule(REPORTE_HOSTIGAMIENTO_RANGO_APP_STORE_MODULE_NAME)) store.registerModule(REPORTE_HOSTIGAMIENTO_RANGO_APP_STORE_MODULE_NAME, reporteStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTE_HOSTIGAMIENTO_RANGO_APP_STORE_MODULE_NAME)) store.unregisterModule(REPORTE_HOSTIGAMIENTO_RANGO_APP_STORE_MODULE_NAME)
    })

    return {

    }
  },
}
</script>

<style>
</style>
