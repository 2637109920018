<template>
  <div>
    <b-card class="mb-1">
      <b-card-title class="mb-1">
        Tasa de crecimiento de denuncias de hostigamiento del año actual respecto al año anterior
      </b-card-title>

      <b-row>
        <b-col
          class="border-danger rounded p-1 mx-1 mt-1"
          xl="5"
          lg="5"
          md="5"
          sm="11"
        >
          <h3 class="text-center text-danger">
            <strong> <em> AÑO ANTERIOR: {{ anioAnterior.anio }}</em></strong>
          </h3>
          <h6> <strong>  DENUNCIAS RECIBIDAS: </strong> {{ anioAnterior.recibidas }} </h6>
          <h6> <strong>  DENUNCIAS PENDIENTES: </strong> {{ anioAnterior.pendientes }} </h6>
          <h6> <strong>  DENUNCIAS CONFIRMADAS: </strong> {{ anioAnterior.confirmadas }}</h6>
          <h6> <strong> DENUNCIAS FALSAS: </strong>  {{ anioAnterior.falsas }}</h6>

        </b-col>
        <b-col
          class="border-success rounded p-1 mx-1 mt-1"
          xl="5"
          lg="5"
          md="5"
          sm="11"
        >
          <h3 class="text-center text-success">
            <strong> <em> AÑO ACTUAL: {{ anioActual.anio }}</em></strong>
          </h3>
          <h6> <strong>  DENUNCIAS RECIBIDAS: </strong> {{ anioActual.recibidas }} </h6>
          <h6> <strong>  DENUNCIAS PENDIENTES: </strong> {{ anioActual.pendientes }} </h6>
          <h6> <strong>  DENUNCIAS CONFIRMADAS: </strong> {{ anioActual.confirmadas }}</h6>
          <h6> <strong> DENUNCIAS FALSAS: </strong>  {{ anioActual.falsas }}</h6>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <!-- apex chart -->
          <div class="text-center">
            <strong class="text-danger">TASA DE CRECIMIENTO DE DENUNCIAS FALSAS</strong>

          </div>
          <vue-apex-charts
            class=" m-0 p-0"
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptionsFalsas"
            :series="seriesFalsas"
          />
        </b-col>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >

          <!-- apex chart -->
          <div class="text-center">
            <strong class="text-success">TASA DE CRECIMIENTO DE DENUNCIAS CONFIRMADAS</strong>
          </div>
          <vue-apex-charts
            class=" m-0 p-0"
            type="radialBar"
            height="270"
            :options="supportTrackerRadialBar.chartOptionsConfirmado"
            :series="seriesConfirmadas"
          />

        </b-col>

      </b-row>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import reporteStoreModule from './reporteStoreModule'

export default {
  components: {

    BCard,
    BCardTitle,
    VueApexCharts,

    BRow,
    BCol,

  },
  data() {
    return {
      anioAnterior: {},
      anioActual: {},

      seriesFalsas: [0],
      seriesConfirmadas: [0],
      supportTrackerRadialBar: {

        chartOptionsConfirmado: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.success],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Denuncias confirmadas'],
        },
        chartOptionsFalsas: {
          plotOptions: {
            radialBar: {
              size: 150,
              offsetY: 20,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '65%',
              },
              track: {
                background: '#fff',
                strokeWidth: '100%',
              },
              dataLabels: {
                name: {
                  offsetY: -5,
                  color: '#5e5873',
                  fontSize: '1rem',
                },
                value: {
                  offsetY: 15,
                  color: '#5e5873',
                  fontSize: '1.714rem',
                },
              },
            },
          },
          colors: [$themeColors.danger],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.primary],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            dashArray: 8,
          },
          labels: ['Denuncias falsas'],
        },
      },
    }
  },
  mounted() {
    this.refreshTabla()
  },
  methods: {
    async refreshTabla() {
      await store.dispatch('reportes-hostigamiento-store-module/getReporteTasaCrecimiento').then(response => {
        this.$set(this.anioAnterior, 'anio', response.data.anio_anterior.anio)
        this.$set(this.anioAnterior, 'pendientes', response.data.anio_anterior.data.denuncias_pendientes)
        this.$set(this.anioAnterior, 'recibidas', response.data.anio_anterior.data.denuncias_recibidas)
        this.$set(this.anioAnterior, 'confirmadas', response.data.anio_anterior.data.denuncias_confirmadas)
        this.$set(this.anioAnterior, 'falsas', response.data.anio_anterior.data.denuncias_falsas)

        this.$set(this.anioActual, 'anio', response.data.anio_actual.anio)
        this.$set(this.anioActual, 'recibidas', response.data.anio_actual.data.denuncias_recibidas)
        this.$set(this.anioActual, 'pendientes', response.data.anio_actual.data.denuncias_pendientes)
        this.$set(this.anioActual, 'confirmadas', response.data.anio_actual.data.denuncias_confirmadas)
        this.$set(this.anioActual, 'falsas', response.data.anio_actual.data.denuncias_falsas)

        this.seriesFalsas.splice(0, 1, response.data.tasa_crecimiento_denuncias_falsas)
        this.seriesConfirmadas.splice(0, 1, response.data.tasa_crecimiento_denuncias_confirmadas)
      })
    },

  },
  setup() {
    const REPORTE_HOSTIGAMIENTO_RANGO_APP_STORE_MODULE_NAME = 'reportes-hostigamiento-store-module'

    // Register module
    if (!store.hasModule(REPORTE_HOSTIGAMIENTO_RANGO_APP_STORE_MODULE_NAME)) store.registerModule(REPORTE_HOSTIGAMIENTO_RANGO_APP_STORE_MODULE_NAME, reporteStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REPORTE_HOSTIGAMIENTO_RANGO_APP_STORE_MODULE_NAME)) store.unregisterModule(REPORTE_HOSTIGAMIENTO_RANGO_APP_STORE_MODULE_NAME)
    })

    return {

    }
  },
}
</script>
